import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "generative-adversaries",
      "style": {
        "position": "relative"
      }
    }}>{`Generative Adversaries`}<a parentName="h1" {...{
        "href": "#generative-adversaries",
        "aria-label": "generative adversaries permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`This session will be devoted to picking a suitable training dataset and generative model to build during the weekly hacking sessions. The plan is to re-train StyleGAN2 on a lo-res image dataset of an NFT collection such crypto punks.`}</p>
    <h2 {...{
      "id": "preparation",
      "style": {
        "position": "relative"
      }
    }}>{`Preparation`}<a parentName="h2" {...{
        "href": "#preparation",
        "aria-label": "preparation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <ul>
      <li parentName="ul">{`Join our `}<a parentName="li" {...{
          "href": "https://github.com/AlgoveraAI/generative-art"
        }}>{`Github repo`}</a>{` and familiarise yourself with the resources there.`}</li>
    </ul>
    <h2 {...{
      "id": "recording",
      "style": {
        "position": "relative"
      }
    }}>{`Recording`}<a parentName="h2" {...{
        "href": "#recording",
        "aria-label": "recording permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Video src="https://www.youtube-nocookie.com/embed/nG2L4bV0a1U" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      